export enum Category {
  Housing = 'Housing',
  Utilities = 'Utilities',
  Subscriptions = "Subscriptions",
  Debt = 'Debt',
  Entertaiment = "Entertainment",
  UBER_EATS = "Uber Eats",
  UBER = "Uber",
  Other = "Other",
  Food = "Food",
  Personal = "Personal",
  Transportation = 'Transportation',
  Entertainment = "Entertainment",
  Vacations = "Vacations",
  Health = "Health",
  Shopping = "Shopping",
  Gift = "Gift & Donations",
}

export const Categories:Category[] = [
  Category.Debt,
  Category.Housing,
  Category.Subscriptions,
  Category.Utilities,
  Category.Food,
  Category.Shopping,
  Category.Entertainment,
  Category.Personal,
  Category.Transportation,
  Category.Gift,
  Category.Vacations,
  Category.Health,
  Category.UBER,
  Category.UBER_EATS,
  Category.Other,
];


